import React, {Fragment} from 'react';
import Header from '../layout/header';
import { Footer } from '../layout/footer';
import { BreadcrumbWraper } from '../content/element/breadcrumb';
import { ContactForm2 } from '../content/element/contact-form';
import { WidgetContactInfo } from '../content/element/widget';
import { connect } from 'react-redux';
const Contact = (props) => {
    const light = props.logo[0].light;
    return (
        <Fragment>
            {/* Header section start */}
            <section className="header-breadcrumb bgimage overlay overlay--dark">
                <div className="bg_image_holder"><img src="./assets/img/breadcrumb1.jpg" alt="" /></div>
                <div className="mainmenu-wrapper">
                    <Header logo={light} class="menu--light" />  
                    <title>Fontaneros en Los Angeles</title>                  
                </div>
                {/* <!-- ends: .mainmenu-wrapper --> */}
                <BreadcrumbWraper title="Contact Us" />
            </section>
            {/* Header section end */}
            <section className="contact-area section-bg p-top-100 p-bottom-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="widget atbd_widget widget-card contact-block">
                                <div className="atbd_widget_title">
                                    <h4><span className="la la-envelope"></span> Contact Form</h4>
                                </div>
                                <div className="atbdp-widget-listing-contact contact-form">
                                    <ContactForm2 />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="widget atbd_widget widget-card">
                                <div className="atbd_widget_title">
                                    <p>
                                    Conoce todo lo que debes saber sobre la fontanería en Los Ángeles
Según la Organización Mundial de la Salud, los fontaneros son uno de los trabajadores que más importancia pueden tener en todo el mundo ¿Curioso no?
Al ver un grifo, un inodoro o una cuenca, nadie puede imaginarse por tan solo un instante todo el gran trabajo que hay detrás de cada elemento, que si hacemos un analisis simple son parte esencial de nuestra vida cotidiana, pues todos los días vamos al baño a lavarnos los dientes, las manos, nos duchamos y hacemos nuestras necesidades fisiológicas, por dar unos cortos ejemplos. 
Entonces no es de extrañarse que la fontanería sea uno de esos oficios indispensables en cualquier rincón del mundo, de hecho, en pro del cuidado de las personas que lo ejercen se creó el Consejo Mundial de Fontanería, el cual se encuentra permanentemente concienciando a la población sobre el papel fundamental que cumple la fontanería en la protección del medio ambiente. 
Es por ello que los fontaneros son de gran importancia en nuestros hogares, sitios de trabajo, centros comerciales, etc. En las siguientes líneas queremos hablarte más de ello y ofrecerte diferentes opciones para que consigas un buen servicio de fontanería Los Angeles, que realicen reparación de baños, grifos y fregaderos, limpieza de drenaje, servicio de emergencia, plomería residencial, reparación de calentadores de agua, etc. 
Beneficios de iniciarse en la fontanería
Ahora bien, como todas saben, el mantenimiento de nuestros hogares es algo que debemos cuidar y mantener día tras día, porque al fin y al cabo este es el lugar en donde pasamos más tiempo (exceptuando el trabajo) y es importante manerlo en perfecto estado, claro, dentro de nuestras posibilidades. 
Cuando se rompe una tubería o se produce una avería que tiene que ver con la distribución de agua dentro del hogar, lo primero que hacemos es tomar el teléfono y llamar a nuestro fontanero o plomero de confianza el cual puede solucionar el problema rápidamente. Pero en este caso, lo más importante es que llames a una persona que cuente con gran experiencia y conocimiento en la materia, ya que al final puede que consigas una reparación satisfactoria pero también te puedes exponer a un dolor de cabeza si no te arreglan bien la falla. Entonces, por ello es importante contratar los servicios de un personal que se encuentre altamente calificado para realizar la tarea que estás pidiendo. 
Pero que tal si en vez de contratar a un tercero eres tu el dueño del servicio, una buena idea ¿No?
Es por ello que queremos darte algunas razones por las que iniciarte en el negocio de la fontanería es una buena opción.
Claro, es importante saber que antes de tomar esta decisión debes reflexionar un poco y saber que comenzar en este negocio requiere de hacer cursos, certificaciones, adquirir experiencia y más, pero es una decisión que con constancia te puede generar grandes beneficios a mediano y largo plazo, es una idea tan buena como comenzar un negocio familiar de cualquier índole. 
Estas empresas ofrecen a sus clientes una amplia gama de servicios que van desde la reparación de calentadores, limpieza de drenajes, grifos y fregaderos, servicio de emergencia y plomería residencial, hasta mantenimiento preventivo y más. Los fontaneros locales son de gran confianza y con experiencia comprobable. 
Ventajas de contratar fontanería profesional en Los Ángeles
Como te mencionamos más arriba, las empresas de fontanería profesional que se encuentran en Los Ángeles no solo se encargan de reparar averías y ya, de hecho, el servicio se va más hacia lo complejo, pues a demás de contar con un formación seria y una experiencia compobable, estos sitios cuentan con los equipos necesarios para poder hacer trabajos seguros y eficientes. Es por ello que queremos darte una visión sobre las ventajas de contratar este tipo de empresas. 
Como ya te mencionamos, además de poseer la experiencia y el conocimiento necesario, los especialistas de estas empresas tienen las herramientas y equipos que necesitan para completar los trabajo rápido y de forma segura, a diferencia de servicios particulares que muchas veces carecen de este tipo de cosas. Esto te dará más tranquilidad y tendrás un trabajo garantizado.
A demás de prestar servicios en la reparación de averías, las grandes empresas ofrecen a sus clientes una gran variedad de servicios que se adaptan a las necesidades que se tengan en el momento. También tienen personal capacitado para instalar calentadores, reparar lavadoras, secadoras, grifos y fregaderos, trituradoras de basura, etc. 
Obtendrás un servicio garantizado, pues no está mal contactar a un fontanero particular, pero una empresa te ofrece la garantía que un tercero a lo mejor no te puede dar. Pero, ¿Por qué te decimos esto? Bueno, porque en ocasiones puede pasar que el fontanero no haga bien su trabajo y en vez de mejorar la falla esta empeore, entonces para evitar más problemas muchas veces en mejor ponerse en manos de empresas conocidas que pueden ayudarte rápidamente. 
Puedes ahorrar dinero, ya que existen muchas opciones de empresas y puedes escoger la que más se adapte a tus necesidades en ese momento. Siempre es bueno realizar una buena investigación y preguntarle a los que saben sobre el tema. No te vayas a la primera opción, siempre es bueno tener referencias para escoger siempre lo mejor. 
Cualidades
En el mismo orden de ideas del punto anterior, queremos comentarte que además de saber las ventajas que ofrece el contratar una empresa confiable de fontanería en Los Ángeles también es importante saber las cualidades que debe tener todo buen plomero. Claro, recuerda que cada persona es muy diferente a la otra, por lo que determinar cuál es el mejor plomero no es tarea fácil, pero conocer sus cualidades te ayudarán en esta tarea. 
En primer lugar, todo buen plomero debe contar con una licencia o certificación que refleje que está apto para cumplir con las tareas de plomería. Esto sin duda alguna es uno de los punto más importantes pues aquí podrás ver que es un especialista y no una persona que no cuenta con la experiencia necesaria. 
En segundo lugar debe contar con experiencia. Este también es un punto bien importante porque estarás confiando el bienestar de tu hogar en un tercero que se supone debe saber del tema. Poner tus averías de fontanería en manos inexpertas podría ser un caos y el remedio será peor que la enfermedad. 
Tener valores de respeto hacia sus clientes es una cualidad que todo fontanero debe tener, bueno, es una cualidad que cualquier persona debería tener en realidad, pero en este caso el plomero debe ser cordial con las personas a las que va a trabajarle y ser muy educado. Esto hablará muy bien de él como profesional y como humano. 
La puntualidad y la pulcritud son otras cualidades de gran importancia en todo buen plomero. El profesional puede ser muy bueno y eficiente en su trabajo, pero si siempre llega tarde es un punto negativo para su perfil, lo mismo pasa si no mantiene el sitio de trabajo limpio, ya que esto habla mucho de su personalidad con experto en la materia. La organización ante todo. 
Los fontaneros siempre deben tener la seguridad como su prioridad. A la hora de realizar algún trabajo es muy importante que estos digan con procedimientos estrictos de seguridad, para cuidar su integridad, la de la familia que habita el lugar y además poder resolver los incovenientes de forma eficaz. 
Un buen fontanero siempre debe ser fiable e inspirar confianza a sus clientes. Siempre debe asegurarse de cumplir el trabajo en el tiempo establecido y sin ningún tipo de excusa. Esta es una cualidad fundamental, ya que de esta forma los clientes tendrán voz y voto para poder recomendar al fontanero con los ojos cerrados. 
Por último y no menos importante, un buen fontanero debe ser experto, hábil y tener mucho profesionalismo a la hora de realizar el trabajo a los clientes. Ellos deben tener las habilidades necesarias y contar con experiencia comprobable para poder tener éxito en la labor a realizar. Es recomendable que de vez en cuando el fontanero realice cursos y actualizaciones para mantenerse siempre en la palestra. 
Cuándo necesitas los servicios de un fontanero
No es loco pensar que en cualquier momento del día a día pueden suscitarse problemas con los sistemas del hogar, una tubería rota, un bloqueo en el inodoro, una rotura en alguna manguera, etc. Es aquí cuando las manos expertas de un fontanero pueden ayudarte a resolver todos esos problemas de tu hogar. 
Contar con los servicios de un buen fontanero te dará mucha tranquilidad, ya que una mala manipulación te podría acarrear más gasto y un gran dolor de cabeza. Siempre es bueno llamar al fontanero de confianza cuando hay una fuga de agua, una avería en el sistema, un grifo dañado, una manguera rota etc, ya que él tiene el conocimiento necesario para reparar el caos.
Ahora ya sabes todo lo que debes saber sobre los servicios de fontanería, así que cuando tengas una avería en tu hogar no dudes en levantar el teléfono y llamar a tu plomero de confianza o contratar los servicios de una buena empresa. No dejes la integridad de tu hogar en manos de cualquier persona, siempre opta por la especialización. 

 




                                    </p>

                                </div>
                                <WidgetContactInfo />                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>

           <Footer />
        </Fragment>
    )
}
const mapStateToProps = state => {
    return {
        list: state.list,
        login : state.login,
        logo: state.logo
    }
}

export default connect(mapStateToProps)(Contact);
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import {createStore, applyMiddleware, compose} from 'redux';
import rootReducers from './Store/Reducers/rootReducer';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';

import { hydrate, render } from "react-dom";

const store = createStore(
    rootReducers,
    compose( 
            applyMiddleware(thunk)                  
        )
    ) 

    const rootElement = document.getElementById("root");
    if (rootElement.hasChildNodes()) {
      hydrate(<Provider store={store}><App /></Provider>, rootElement);
    } else {
      render(<Provider store={store}><App /></Provider>, rootElement);
    }


